<template>
  <div>
    <div class="container-sm-lot p-md-1 pt-1 pl-75 pr-75 ctx-rex">
      <div class="top-bxt">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          สมัครสมาชิก
        </h3>
      </div>
      <div class="cards ctx-card w-100 my-2">
        <div class="card-body p-1">
          <div>
            <validation-observer ref="stepFinish">
              <div>
                <label
                  for="tel"
                  class="text-primary"
                > ชื่อผู้ใช้ </label>

                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    v-model="username"
                    class="form-valid"
                    :state="errors.length > 0 ? false : null"
                    :formatter="formatYear"
                    placeholder="กรุณากรอก Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <div class="mt-1">
                <label
                  for="Password"
                  class="text-primary"
                > รหัสผ่าน </label>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <div class="pass-po">
                    <b-form-input
                      v-model="password"
                      class="form-valid"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      placeholder="กรุณากรอกรหัสผ่าน"
                    />
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer pass-txt"
                      size="15"
                      @click="togglePasswordVisibility"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <div class="mt-1">
                <label
                  for="Password"
                  class="text-primary"
                >
                  ยืนยันรหัสผ่าน
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="confirm password"
                  rules="required|confirmed:password"
                >
                  <b-form-input
                    v-model="conPassword"
                    class="form-valid"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                    placeholder="กรุณายืนยันรหัสผ่าน"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <button
                class="btn-login-radius mt-2 mb-1 w-100"
                @click="Register()"
              >
                สมัครสมาชิก
              </button>
            </validation-observer>
          </div>
        </div>

        <div class="text-center">
          <p class="mb-0 text-primary text-primary">
            หากท่านมีบัญชีอยู่แล้ว?
            <a
              class="text-warning"
              @click="loginLink()"
            >เข้าสู่ระบบ</a>
          </p>
        </div>
      </div>
    </div>

    <!-- Modal Bank-->
    <b-modal
      ref="my-modal"
      hide-footer
      hide-header
      centered
    >
      <div class="row mt-1 mb-2">
        <div class="col-12 text-center mb-1">
          <h5 class="mb-0 text-primary">
            โปรดเลือกธนาคาร
          </h5>
        </div>
        <div
          v-for="item in items"
          :key="item.id"
          class="col-md-2 col-3 p-0 gtx"
        >
          <div
            class="card-bank-list"
            @click="selectBank(item.id)"
          >
            <img
              :src="`/bank/${item.code}.jpg`"
              :alt="item.code"
              width="100%"
              :class="{ active: isActive === item.id }"
              :style="{ opacity: getOpacity(item) }"
            >
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Modal Success -->
    <b-modal
      ref="my-success"
      hide-footer
      hide-header
      centered
    >
      <div class="text-center">
        <img
          src="/icon/welcome.svg"
          alt="welcome"
          width="95"
        >

        <h3 class="text-primary text-white">
          สมัครสมาชิกสำเร็จ 🎊
        </h3>

        <p class="mb-0 text-white">
          แนะนำให้แคปเจอร์หน้าจอนี้ไว้
        </p>

        <div class="bx-out">
          <div class="box-user">
            <p class="mb-0 text-white">
              ชื่อผู้ใช้
            </p>

            <p class="mb-0 text-primary text-white">
              {{ regissuc.username }}
            </p>
          </div>

          <div class="box-user">
            <p class="mb-0 text-white">
              รหัสผ่าน
            </p>

            <p class="mb-0 text-primary text-white">
              {{ regissuc.password }}
            </p>
          </div>
        </div>

        <button
          class="btn-golden-radius mt-2 mb-1 w-md-50"
          @click="$refs['my-success'].hide()"
        >
          เข้าสู่ระบบ
        </button>

        <p class="mb-0 text-primary text-white">
          พบปัญหาในการสมัคร? <a
            href="/"
            class="text-warning"
          >ติดต่อเรา</a>
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BFormInput } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email, confirmed } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'Home',
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      confirmed,
      isValid: true,
      isValidLength: false,
      isValidComposition: false,
      isMatched: true,
      invited_by: null,
      // invite_friend: '',
      userInput: '',
      username: null,
      referralCode: null,
      email_id: null,
      fullname: null,
      name: null,
      surname: null,
      bankNumber: null,
      accountName: null,
      errors: {
        name: '',
        surname: '',
        email: '',
        bankNumber: '',
        accountName: '',
      },
      volume: '10',
      step: 1,
      bank: [],
      otp: '',
      password: null,
      conPassword: null,
      items: [],
      isActive: null,
      regissuc: {},
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => item.status === 1)
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.$http.get('/lotto-suvarn/banklist/korean').then(res => {
      this.items = res.data.filter(item => item.status === 1)
    })
    if (this.userData) {
      this.$router.push({ name: 'lottery' })
    }
  },
  mounted() {
    if (this.$route.params.invited_by) {
      // console.log(this.$route.params.invcode)
      // this.knowby = 6
      this.invited_by = this.$route.params.invited_by
    }
    if (localStorage.getItem('invited_by')) {
      // this.knowby = 6
      this.invited_by = localStorage.getItem('invited_by')
    }
    if (this.$route.query.ref) {
      // this.knowby = 6
      this.invited_by = this.$route.query.ref
    }
    if (this.$route.query.register) {
      this.$router.push('/login')
    }
    // this.generateCaptcha()
  },
  methods: {
    formatYear(e) {
      return String(e).substring(0, 10)
    },
    step1() {
      this.$refs.stepOne.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.step = 2;
        }
      })
    },
    step2() {
      this.$refs.stepTwo.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.step = 4;
        }
      })
    },
    Register() {
      this.$refs.stepFinish.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          const obj = {
            username: this.username,
            password: this.password,
            invited_by: this.invited_by,
            // bank: this.bank.code,
            // name: this.name,
            // surname: this.surname,
            // acc_no: this.bankNumber,
            // acc_name: this.accountName,
          }

          this.$http
            .post('/users/store', obj)
            .then(() => {
              useJwt
                .login({
                  email: this.username,
                  password: this.password,
                  agent_id: 1,
                  Detail: {
                    Ip: this.Ip,
                    UserAgent: navigator.userAgent,
                    Platform: navigator.platform,
                  },
                })
                .then(response => {
                  this.OverLay = false
                  const userData = response.data
                  useJwt.setToken(response.data.token)
                  useJwt.setRefreshToken(response.data.refreshToken)
                  localStorage.setItem('userData', JSON.stringify(userData))
                  localStorage.setItem('CheckClick', true)
                  this.$ability.update(userData.ability)
                  this.$http.get('/auth/checklogin').then(res => {
                    localStorage.setItem('CountLogin', res.data)
                  })
                  window.location.reload()
                  const On = JSON.parse(localStorage.getItem('userData'))
                  if (On.role === 'admin') {
                    window.location.href = '/lottery'
                  }
                })
                .catch(error => {
                  this.OverLay = false
                  this.SwalError(error.response.data.message)
                  // this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    validatePasswordCriteria() {
      this.isValidLength = this.password.length >= 6
      this.isValidComposition = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(
        this.password,
      )
    },
    // submitForm() {
    //   this.isMatched = this.password === this.conPassword

    //   // console.log(this.isMatched)
    //   if (this.isMatched) {
    //     this.step = 4
    //   }
    //   // Continue any other logic for submitting the form
    // },
    validateUsername() {
      const regex = /^0\d{9}$/
      this.isValid = regex.test(this.username)

      if (this.isValid) {
        this.step = 2
      }
    },
    handleCaptchaValidation(isValid) {
      if (isValid) {
        this.step = 3
      } else {
        this.$refs['my-captcha'].show()
        this.userInput = ''
      }
    },
    validateCaptcha() {
      this.$refs.capcha.validateCaptcha()
    },
    loginLink() {
      if (this.$route.query.ref) {
        this.$router.push({
          name: 'login',
          query: { ref: this.$route.query.ref },
        })
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    Register2() {
      const obj = {
        tel: this.username,
        password: this.password,
        invited_by: this.invited_by,
        bank: this.bank.code,
        name: this.name,
        surname: this.surname,
        email: this.email,
        acc_no: this.bankNumber,
        acc_name: this.accountName,
      }
      // console.log(obj)
      this.$http
        .post('/users/store', obj)
        .then(() => {
          // console.log(res)
          // this.regissuc = {
          //   username: this.username,
          //   password: this.password,
          // }
          // this.step = 1
          // this.$refs['my-success'].show()
          // this.clear()
          useJwt
            .login({
              email: this.username,
              password: this.password,
              agent_id: 1,
              Detail: {
                Ip: this.Ip,
                UserAgent: navigator.userAgent,
                Platform: navigator.platform,
              },
            })
            .then(response => {
              this.OverLay = false
              const userData = response.data
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              localStorage.setItem('CheckClick', true)
              this.$ability.update(userData.ability)
              this.$http.get('/auth/checklogin').then(res => {
                localStorage.setItem('CountLogin', res.data)
              })
              window.location.reload()
              const On = JSON.parse(localStorage.getItem('userData'))
              if (On.role === 'admin') {
                window.location.href = '/lottery'
              }
              // this.$router
              //   .push(getHomeRouteForLoggedInUser(userData.role))
              //   .catch(error => {
              //     this.OverLay = false
              //     this.$refs.loginForm.setErrors(error.response.data.error)
              //   })
            })
            .catch(error => {
              this.OverLay = false
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    clear() {
      this.username = null
      this.password = null
      this.referralCode = null
      this.bank.code = null
      this.name = null
      this.surname = null
      this.email = null
      this.bankNumber = null
      this.accountName = null
    },
    showBank() {
      this.$refs['my-modal'].show()
    },
    selectBank(id) {
      this.isActive = id
      this.bank = this.items.find(item => item.id === id)

      setTimeout(() => {
        this.$refs['my-modal'].hide()
      }, 500)
    },
    getOpacity(item) {
      return this.isActive === null || this.isActive === item.id ? '1' : '0.2'
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.valid i.fa-check {
  color: green;
}

.invalid i.fa-times {
  color: red;
}
</style>
